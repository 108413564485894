import React from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import PropTypes from 'prop-types';
import { ItemBlock, LoadingBlock, Page } from '@@components';
import { useAdherent, useKeycloakSession } from '@@hooks';
import { useHistory } from 'react-router-dom';
import {
  CONTRACT_TYPE_ASSISTANCE,
  CONTRACT_TYPE_HEALTH,
  CONTRACT_TYPE_PENSIONH,
  contractOrder,
  getContractGaConf,
  getEndDate,
  getHealthOptionLabel,
  getLabel,
  getStartDate,
  isContractOfType,
  isUpcomingContract,
  SEPARATOR,
  splitGuarantees,
  getContractMaConf
} from '@@lib/contracts';
import { sendGAEventIfDefined } from '@@lib/ga';
import { sendMAEventIfDefined } from '@@lib/ma';

const Home = ({ pathConfig }) => {
  const history = useHistory();
  const { user } = useKeycloakSession();
  const { adherent } = useAdherent(user.customerId, ['contracts']);

  const [upcomingContracts, activeContracts] = R.compose(
    R.partition(isUpcomingContract),
    R.propOr([], 'contracts')
  )(adherent);

  const mapContracts = R.compose(
    R.sortBy(contractOrder),
    R.chain(splitGuarantees)
  );

  const mappedActiveContracts = mapContracts(activeContracts);
  const mappedUpcomingContracts = mapContracts(upcomingContracts);


  const areContractsLoading = R.all(RA.isNilOrEmpty)([
    mappedActiveContracts,
    mappedUpcomingContracts
  ]);
  const existsAssistanceContract =
    R.any(isContractOfType(CONTRACT_TYPE_ASSISTANCE))(mappedActiveContracts);

  const existsPensionHContract =
    R.any(isContractOfType(CONTRACT_TYPE_PENSIONH))(mappedActiveContracts);

  const getUrl = (pathConfig, contractInput) => {
    const host = R.path(['contractDetail', 'editPath'], pathConfig);
    const { contract, category, contractType } =
      R.pick(['contract', 'category', 'contractType'], contractInput);
    const contractIdEncoded = encodeURIComponent(contract);
    const categoryEncoded = encodeURIComponent(category);
    return (`${host}/` +
      `${contractIdEncoded}${SEPARATOR}${categoryEncoded}${SEPARATOR}${contractType}` +
      `?withPensionH=${encodeURIComponent(existsPensionHContract)}` +
      `&withAssistance=${encodeURIComponent(existsAssistanceContract)}`
    );
  };

  const onEdit = (url, ga, ma) => () => {
    sendGAEventIfDefined(ga);
    sendMAEventIfDefined(ma);
    history.push(url);
  };

  const contractToJSX = (contract, index) => {

    const healthLabel = isContractOfType(CONTRACT_TYPE_HEALTH)(contract)
      ? getHealthOptionLabel(contract) : '' ;

    const isHealthContract = RA.isNotNilOrEmpty(healthLabel);
    const isUpcoming = isUpcomingContract(contract);
    const endDate = getEndDate(contract);
    const startDate = getStartDate(contract);
    const ga = getContractGaConf(contract);
    const ma = getContractMaConf(contract);

    const endDateLabel = !isUpcoming && RA.isNotNilOrEmpty(endDate) && (
      <div className={'f-contract-date'}>
        {`Date de fin de contrat le ${endDate}`}
      </div>
    );

    const startDateLabel = isUpcoming && RA.isNotNilOrEmpty(startDate) && (
      <div className={'f-contract-date'}>
        {`Ouverture le ${startDate}`}
      </div>
    );

    return (
      <ItemBlock
        key={index}
        onEdit={onEdit(getUrl(pathConfig, contract), ga, ma)}
        icon={getLabel('icon')(contract)}
        isUpcoming={isUpcoming}
      >
        <div className={'f-contract-title'}>
          {getLabel('title')(contract)}
        </div>
        {isHealthContract && (
          <div className={'f-contract-health-option'}>
            {healthLabel}
          </div>
        )}
        {startDateLabel}
        {endDateLabel}
      </ItemBlock>
    );
  };

  return (
    <Page pageTitle={'Contrats'}>
      {areContractsLoading && (<LoadingBlock/>)}
      {RA.isNotNil(mappedActiveContracts) && (
        <div className={'f-contract-list-container'}>
          {RA.mapIndexed(contractToJSX, mappedActiveContracts)}
        </div>)
      }
      {RA.isNotNilOrEmpty(mappedUpcomingContracts) && (
        <>
          <div className={'subsection-title mt-6 mb-4'}>
            {'Contrats à venir'}
          </div>
          <div className={'f-contract-list-container'}>
            {RA.mapIndexed(contractToJSX, mappedUpcomingContracts)}
          </div>
        </>)
      }
    </Page>
  );
};

Home.propTypes = {
  pathConfig: PropTypes.object
};

export default Home;
