import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useCms, useContract, useKeycloakSession } from '@@hooks';
import { BreadCrumb, Loading } from '@@components';
import {
  CONTRACT_TYPE_ASSISTANCE,
  CONTRACT_TYPE_HEALTH,
  CONTRACT_TYPE_PENSIONH,
  CONTRACT_TYPE_PENSIONL,
  getHealthOptionLabel,
  getLabel,
  isAllCoversDocument,
  isBaseRefundDocument,
  isBenefitsDocument,
  isBenefitsGuide,
  isOnlineDeclarationHelpGuide,
  isExampleRefundDocument,
  isWorkStoppageDeclarationGuide,
  SEPARATOR, isAllCoversDDCDocument
} from '@@lib/contracts';
import * as RA from 'ramda-adjunct';
import { useFetchQuery } from '@@hooks/useDocument';
import { QGenericDocumentsQuery } from '@@queries/QGenericDocument';
import ContractDetailsHeader from './ContractDetailsHeader';
import ContractDetailsCovers from './ContractDetailsCovers';
import AssistanceBlock from './AssistanceBlock';
import HealthPrimaryBlock from '@@pages/contracts/HealthPrimaryBlock';
import PensionPrimaryBlock from '@@pages/contracts/PensionPrimaryBlock';
import HealthSecondaryBlock from '@@pages/contracts/HealthSecondaryBlock';
import PensionSecondaryBlock from '@@pages/contracts/PensionSecondaryBlock';
import EditInfosProPrimaryBlock from '@@pages/contracts/EditInfosProPrimaryBlock';
import {prop, startsWith} from "ramda";

const parseUrl = (root) => {
  const reg = new RegExp(`^${root}/(.+)${SEPARATOR}(.+)${SEPARATOR}(.+)$`);
  const match = decodeURI(location.pathname).match(reg);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const withPensionH = R.propOr(false, 'withPensionH')(params);
  const withAssistance = R.propOr(false, 'withAssistance')(params);

  const isNotMatching = R.compose(R.not, R.equals(4), R.length);
  if (R.anyPass([RA.isNilOrEmpty, isNotMatching])(match)) {
    return ['', '', '', '', ''];
  }

  return R.compose(
    R.append(withAssistance),
    R.append(withPensionH),
    R.takeLast(3)
  )(match);
};

const ContractDetails = ({ pathConfig }) => {
  const { user } = useKeycloakSession();
  const { mercer } = useCms(['mercer']);

  const mercerUrl = R.propOr('', 'mercerUrl', mercer);

  const [contractIdEncoded, categoryEncoded, contractType, withPensionH, withAssistance] =
    parseUrl(pathConfig.editPath);
  const contractId = decodeURIComponent(contractIdEncoded);
  const category = decodeURIComponent(categoryEncoded);
  const { contract: originalContract } = useContract(
    user.customerId, contractId, category);
  console.log('originalContract', originalContract)

  const isType = R.equals(contractType);
  const isHealthContract = isType(CONTRACT_TYPE_HEALTH);
  const isPensionHContract = isType(CONTRACT_TYPE_PENSIONH);
  const isPensionLContract = isType(CONTRACT_TYPE_PENSIONL);
  const isAssistanceContract = isType(CONTRACT_TYPE_ASSISTANCE);
  const isPensionContract = R.or(isPensionHContract, isPensionLContract);

  const assocGuarantee = type => R.compose(
    R.assocPath(
      ['detail', 'complementaryText'],
      R.pathOr([], ['detail', type, 'complementaryText'], originalContract)),
    R.assocPath(
      ['detail', 'guarantee'],
      R.pathOr([], ['detail', type, 'guarantee'], originalContract))
  );

  const pickAndAssocGuarantee = R.cond([
    [R.always(isHealthContract), assocGuarantee('health')],
    [R.always(isPensionContract), assocGuarantee('pension')],
    [R.always(isAssistanceContract), assocGuarantee('assistance')]
  ]);

  const contract = R.compose(
    pickAndAssocGuarantee,
    R.assoc('contractType', contractType)
  )(originalContract);

  // Load urls for each file
  const [fileIds, setFileIds] = useState({});
  const {
    isLoaded: isLoadedListDocuments,
    data: dataListDocuments
  } = useFetchQuery(QGenericDocumentsQuery, { id: user.customerId });
  useEffect(() => {
    if (isLoadedListDocuments) {
      const list = R.pathOr([], ['adherent', 'genericDocuments'],
        dataListDocuments);

      const newFileIds = R.applySpec({
        allCovers:  R.find(isAllCoversDocument(contract)),
        baseRefund: R.find(isBaseRefundDocument),
        exampleRefund: R.find(isExampleRefundDocument),
        benefitsGuide: R.find(isBenefitsGuide),
        onlineDeclarationHelpGuide: R.find(isOnlineDeclarationHelpGuide),
        benefitsDocument: R.find(isBenefitsDocument),
        workStoppageDeclarationGuide: R.find(isWorkStoppageDeclarationGuide)
      })(list);
      console.log('newFileIds', newFileIds);
      setFileIds(newFileIds);
    }
  }, [isLoadedListDocuments, dataListDocuments]);

  if (RA.isNilOrEmpty(originalContract)) {
    return <Loading/>;
  }

  const breadCrumb = (
    <BreadCrumb
      rootPath={'contrats'}
      rootName={'Contrats'}
      location={getLabel('title')(contract)}/>
  );

  const title = (
    <div className={'subsection-title'}>
      {getLabel('title')(contract)}
    </div>
  );

  // just below the title (ex 'FORMULE FAMILLE')
  const healthLabel = isHealthContract ? getHealthOptionLabel(contract) : '';
  const pill = RA.isNotNilOrEmpty(healthLabel) && (
    <div className={'f-contract-details-pill'}>
      {healthLabel}
    </div>
  );

  // Pour les contrats assistance/prévoyance on n'affiche pas ces liens
  const hidePdfLinks = R.or(isPensionContract, isAssistanceContract);
  const contractDetailsPrimaryBlock = (
    <ContractDetailsCovers
      contract={contract}
      loading={R.not(isLoadedListDocuments)}
      allCovers={fileIds.allCovers}
      baseRefund={fileIds.baseRefund}
      exampleRefund={fileIds.exampleRefund}
      hidePdfLinks={hidePdfLinks}/>
  );

  // Primary col: "Mes bénéficiaires santé"
  const healthPrimaryBlock = isHealthContract && (
    <HealthPrimaryBlock mercerUrl={mercerUrl}/>
  );

  // Primary col: "Mes informations professionnelles"
  // - toujours affiché sur contrat prévoyance hospitalière
  // - affiché sur contrat prévoyance libérale si pas de prévoyance hospitalière
  const showInfosPro = R.or(
    isPensionHContract,
    R.and(isPensionLContract, R.not(withPensionH))
  );
  const editInfosProPrimaryBlock = showInfosPro && (
    <EditInfosProPrimaryBlock/>
  );

  // Primary col: "Modifier ma clause décès"
  const pensionPrimaryBlock = isPensionContract && (
    <PensionPrimaryBlock
      loading={R.not(isLoadedListDocuments)}
      onlineDeclarationHelpGuide={fileIds.onlineDeclarationHelpGuide}
      benefitsGuide={fileIds.benefitsGuide}
      benefitsDocument={fileIds.benefitsDocument}
    />
  );

  // Secondary col: "Mes derniers remboursements"
  const healthSecondaryBlock = isHealthContract && (
    <HealthSecondaryBlock mercerUrl={mercerUrl}/>
  );

  // Secondary col: "Déclarer un évènement"
  const pensionSecondaryBlock = isPensionContract && (
    <PensionSecondaryBlock workStoppageDeclarationGuide={fileIds.workStoppageDeclarationGuide}/>
  );

  // Secondary col: "Assistance APPA"
  const doShowAssistBlock = R.or(
    isAssistanceContract,
    R.and(isHealthContract, withAssistance)
  );
  const assistanceSecondaryBlock = doShowAssistBlock && (<AssistanceBlock contractType={contractType}/>);

  return (
    <div className="f-base-page-container">
      <div className="f-page">
        <div className="f-page-body">

          {breadCrumb}

          <div className="f-page-content-header">
            <div className="f-contract-details-head-container">
              <div className="f-contract-details-head-title">
                {title}
                {pill}
              </div>
              <div className="f-contract-details-head-btn">
                <ContractDetailsHeader contract={contract}/>
              </div>
            </div>
          </div>

          <div className="f-contract-details-grid-container">
            <div className="f-contract-details-primary-col">
              {contractDetailsPrimaryBlock}
              {healthPrimaryBlock}
              {editInfosProPrimaryBlock}
              {pensionPrimaryBlock}
            </div>
            <div className="f-contract-details-secondary-col">
              {healthSecondaryBlock}
              {pensionSecondaryBlock}
              {assistanceSecondaryBlock}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

ContractDetails.propTypes = {
  pathConfig: PropTypes.object
};

export default ContractDetails;
