import React from 'react';
import PropTypes from 'prop-types';
import Message from './Message';
import * as R from 'ramda';
import { discussionStatus } from '@@enum';
import { Link, useLocation } from 'react-router-dom';

const MessagesList = ({
  items,
  pagination,
  status,
  pendingSignRequest,
  signature
}) => {
  const location = useLocation();

  // * Sort messages for having them chronologically
  const cleanItems = R.compose(
    R.sortBy(R.prop('order')),
    R.defaultTo([])
  );

  const [messages, setMessages] = React.useState([]);

  const messageListRef = React.useRef(null);

  const scrollToBottom = () => {
    if (R.isNil(messageListRef.current)) return;
    messageListRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest'
    });
  };

  const signUrl = R.compose(
    R.concat(R.__, '/signature'),
    R.prop('pathname')
  )(location);

  React.useEffect(async () => {
    await setMessages(cleanItems(items));
    scrollToBottom();
  }, [items]);

  console.log('messages', items)
  return (
    <ul className="f-messaging-messages">
      {messages.map((item) => <Message
        key={item.id}
        value={item}
        messageId={item.id}
        signature={signature}
      />)}
      <div ref={messageListRef}/>
      {
        /* Display button to sign pending requests */
        pendingSignRequest && (
          <div>
            <Link className={'f-button f-button-coral'} to={signUrl}>
              {'Signer les documents en attente'}
            </Link>
          </div>
        )
      }
      {
        R.equals(status, discussionStatus.CLOSED) && (
          <div className={'f-messaging-message-container'}>
            <div className={'f-messaging-message f-messaging-message-closed-message'}>
              {'Votre conseiller a clos la discussion, vous ne pouvez plus y' +
              ' apporter de modifications. Si vous souhaitez nous communiquer' +
              ' des informations complémentaires, vous avez la possibilité de' +
              ' nous adresser une nouvelle demande.'}
            </div>
          </div>
        )
      }
    </ul>
  );
};

MessagesList.propTypes = {
  status: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      attachments: PropTypes.array.isRequired,
      dates: PropTypes.shape({
        creation: PropTypes.string.isRequired,
        lastUpdate: PropTypes.string
      }).isRequired,
      id: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
      recipients: PropTypes.arrayOf(
        PropTypes.string
      ).isRequired,
      sender: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default MessagesList;
