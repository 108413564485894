import React from 'react';
import { QAdherentContractDetailsQuery } from '@@queries/QAdherentContractDetails';

const R = require('ramda');
const { fetchQuery } = require('react-relay');
const { useRelayEnvironment } = require('react-relay');

export const useContract = (id, contractId, category) => {
  const environment = useRelayEnvironment();
  const [contract, setContract] = React.useState({});

  const idRef = React.useRef();
  const contractIdRef = React.useRef();
  const categoryRef = React.useRef();
  React.useEffect(() => {
    if (R.equals(idRef.current, id) && R.equals(contractIdRef.current, contractId) && R.equals(categoryRef.current, category)) return;
    idRef.current = id;
    contractIdRef.current = contractId;
    categoryRef.current = category;

    getContract(id, contractId, category);
  }, [id, contractId, category]);

  const getContract = async (id, contractId, category) => {
    const { adherent } = await fetchQuery(environment, QAdherentContractDetailsQuery, {
      id,
      filter: {
        contractId,
        category
      }
    }).toPromise();
    setContract(R.propOr({}, 'contract', adherent));

  };


  return {
    contract
  };
};

export default useContract;
